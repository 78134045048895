<template>
  <div
    class="
      flex-col
      hidden
      w-48
      h-full
      px-2
      text-sm text-gray-500
      border-r
      bg-base-900
      md:flex
      fixed
      top-0
      left-0
    "
  >
    <div class="mt-5 border-b border-indigo-900 pb-3">
      <w-logo-orsai class="text-white w-32" />
    </div>
    <div
      v-if="user.isOwner"
      class="flex items-center px-2 py-1 border-b border-gray-800 justify-left"
    >
      <div class="pr-2">
        <!-- <img :src="gravatar" class="w-8 h-8 bg-green-500 rounded" /> -->

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div class="text-xs">
        <div class="font-semibold truncate">
          {{ user.name || "Administrador" }}
        </div>
        <div v-if="user.isAdmin" class="text-xs font-thin text-gray-600">
          Super Administrador
        </div>
        <div v-if="user.isOwner" class="text-xs font-thin text-gray-600">
          Administrador
        </div>
      </div>
      <div
        class="
          flex
          items-center
          justify-end
          hidden
          w-10
          h-10
          text-gray-500
          cursor-pointer
          hover:text-gray-300
        "
      >
        <svg
          class="w-3 h-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
    <w-menu-items />
    <div class="border-t border-indigo-900 pt-2">
      <small class="italic text-white opacity-25">Versión 1.0.1</small>
    </div>
  </div>
</template>

<script>
var md5 = require("md5");

import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["user"]),
    gravatar() {
      return "https://www.gravatar.com/avatar/" + md5(this.user.email);
    },
  },
};
</script>

<style lang="scss" scoped></style>
