<template>
  <div
    class="flex justify-center min-h-screen px-2 bg-gray-400 body-bg md:px-0 max-w-screen-3xl"
  >
    <transition
      leave-active-class="transition-all duration-1000 ease-in-out"
      enter-active-class="transition-all duration-1000 ease-in-out"
      enter-class="opacity-0 "
      enter-to-class="opacity-100 "
      leave-to-class="opacity-100"
      leave-class="opacity-0"
    >
      <div v-if="auth" class="flex w-full">
        <w-nav-bar />
        <router-view class="flex-1 overflow-hidden bg-white md:ml-48" />
      </div>
      <w-loading v-else></w-loading>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      auth: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    user(user) {
      if (user) {
        this.auth = true;
      }
    },
  },
  created() {
    const self = this;
    if (!window.qrCode) {
      window.qrCode = true;
      let url = "";
      document.addEventListener("keydown", function (event) {
        // Check if the pressed key is not the enter key
        if (event.keyCode !== 13) {
          // Only characters A-z and 0-9 are allowed and :,//,.,- are allowed
          if (
            !(
              (event.keyCode >= 65 && event.keyCode <= 90) ||
              (event.keyCode >= 48 && event.keyCode <= 57) ||
              event.keyCode === 186 ||
              event.keyCode === 191 ||
              event.keyCode === 190 ||
              event.keyCode === 189
            )
          ) {
            return;
          }

          // Append the pressed key to the URL
          url += event.key;
        } else {
          // Verificar si es una URL
          if (url.includes("http")) {
            // Extract orderId
            // https://admin.orsai.org/retiros?orderId=XXXX
            const orderId = url.split("orderId=")[1];

            // Verificar que orderId exista y que tenga mas de  5 chars
            if (!orderId || orderId.length < 5) {
              url = "";
              return;
            }

            self.$router.push({ path: "/retiros", query: { orderId } });
            url = "";
          }
        }
      });
    }

    // Check https
    if (
      window.location.protocol !== "https:" &&
      process.env.NODE_ENV === "production"
    )
      window.location =
        "https://" +
        window.location.hostname +
        window.location.pathname +
        window.location.search;

    console.log("CHECK LOGIN");
    if (!this.$store.state.auth.user) {
      this.$store
        .dispatch("auth/authenticate")
        .then(() => {
          console.log("auth");
          this.auth = true;
        })
        .catch(() => {
          console.log("no auth");
          this.$router.push("/login");
        });
    } else {
      this.auth = true;
    }
  },
};
</script>
